* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media screen and (max-width: 640px) {
  .locationMaps iframe {
    width: 350px;
    height: 350px;
  }
}

.textsContainer {
  width: 100%;
  max-width: 100rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  line-height: 1.5;
  text-align: justify;
  font-size: 1.2rem;
}
.textsContainer > ul {
  list-style: inside;
  margin-bottom: 0.8rem;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.row ul {
  list-style: none;
  margin-right: 10rem;
}
.row ul li {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}
.textsContainer .title {
  font-size: 1.2rem;
  font-weight: bold;
}
.textsContainer .subtitle {
  font-weight: normal;
  font-size: 1.2rem;
}
b,
i,
strong {
  color: #f58115;
}

.heg-img {
  float: right;
}
.bm {
  float: right;
}

.i0 {
  width: 600px;
}
.i1 {
  width: 500px;
}
.i2,
.i3 {
  float: none;
  width: 330px;
}
.bi1 {
  width: 800px;
}
.bi2,
.bi3 {
  width: 400px;
}
@media screen and (max-width: 600px) {
  .i1 {
    width: 330px;
  }
  .bi1,
  .bi2,
  .bi3 {
    width: 330px;
  }
}
